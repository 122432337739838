export const handleResponse = (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const { data } = response;
  if (data.status === "ERROR") {
    return Promise.reject(data);
  }

  return data;
};

export const handleError = (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
};
