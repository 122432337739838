import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => {
      // const loggedIn = localStorage.getItem("authenticated");
      // const newInstallation = localStorage.getItem("newInstallation");
      // console.log(!loggedIn, newInstallation);
      // if (!loggedIn || newInstallation) {
      //   return import("@/views/pages/WelcomePage");
      // }
      return import("@/views/pages/Dashboard");
    },
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("@/views/pages/Schedule"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/pages/ProfilePage"),
    meta: {
      requiresAuth: true,
      authRequired: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/pages/PrivacyPolicy"),
  },
  {
    path: "/change-password/:token",
    name: "ChangePassword",
    component: () => import("@/views/pages/ChangePassword"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.API_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/", "/schedule", "/privacy-policy", "/dashboard"];
  const authRequired =
    !publicPages.includes(to.path) && !to.path.includes("change-password/");
  const loggedIn = localStorage.getItem("authenticated");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
