import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import apiAxios from "@/api";

// Pinia Store
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

//

// App Styles
import "@/assets/app.scss";

const validateUser = async () => {
  await apiAxios.User.validateUser().then(null, (data) => {
    if (data.response.status === 401) {
      localStorage.clear();
    }
  });
};

const setConfirmationId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const confid = urlParams.get("confid");
  if (confid !== null) {
    localStorage.setItem("confid", confid);
  }
};

validateUser().then(() => {
  setConfirmationId();
});

createApp(App)
  .use(pinia)
  .use(router)
  .use(SetupCalendar)
  .use(Toast, {
    position: "bottom-right",
    transition: "Vue-Toastification__bounce",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
  })
  .component("Calendar", Calendar)
  .component("DatePicker", DatePicker)
  .mount("#app");
