export default (axios) => {
  return {
    createOrder: async (payload) => {
      return axios.post("/api/order/", payload).then((response) => {
        if (!response.message) {
          if (response.success) {
            return response;
          }
        }

        return Promise.reject(response);
      });
    },
  };
};
