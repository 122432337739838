import axios from "axios";
import { handleResponse, handleError } from "./helpers";

import userApi from "./modules/user";
import orderApi from "./modules/orders";

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

apiAxios.interceptors.response.use(handleResponse, handleError);

export { apiAxios };

export default {
  User: userApi(apiAxios),
  Order: orderApi(apiAxios),
};
