export default (axios) => {
  return {
    login: async (payload) => {
      return axios.post("/api/auth/login", payload).then((response) => {
        // console.log(response);
        if (!response.message) {
          return response;
        }

        return Promise.reject(response);
      });
    },
    register: async (payload) => {
      return axios.post("/api/auth/register", payload).then((response) => {
        if (response) {
          return response;
        }

        return Promise.reject(response);
      });
    },
    getUsersData: async () => {
      return axios.get("api/user/me").then((response) => {
        if (response) {
          return response;
        }

        return Promise.reject(response);
      });
    },
    validateUser: async () => {
      return axios.get("api/validate").then(null, (response) => {
        if (response.response.status === 401) {
          return Promise.reject(response);
        }
      });
    },
    updateProfile: async (payload) => {
      return axios.patch("/api/user/profile", payload).then((response) => {
        if (response.success) {
          return response;
        }

        return Promise.reject(response);
      });
    },
    forgotPassword: async (payload) => {
      return axios
        .post("api/auth/send-email", null, {
          params: payload,
        })
        .then((response) => {
          if (response.success) {
            return response;
          }

          return Promise.reject(response);
        });
    },
    changePassword: async (payload) => {
      // console.log("payload: ", payload);
      return axios
        .patch("/api/auth/change-password", payload)
        .then((response) => {
          if (response.success) {
            return response;
          }

          return Promise.reject(response);
        });
    },
    getUtilityCompany: async (payload) => {
      return axios
        .get("api/utility-company", {
          params: payload,
        })
        .then((response) => {
          return response;
        });
    },
  };
};
